// This mixins is used for every component that is using SelectElevesV2
// It is made to store the list of SallesUsagers from the popup in data

export default {
  data: () => ({
    selectedSallesUsager: [],
    displayedSallesUsagers: []
  }),
  methods: {
    onSelectSallesUsager (usagers) {
      this.selectedSallesUsager = usagers
    },
    onDisplaySallesUsager (usagers) {
      this.displayedSallesUsagers = usagers
    },
    async initialiseSallesUsagersFromIdentifiant (identifiant) {
      const salleUsagers = await this.getSalleUsagersFromCalendrierElementIdentifiant(identifiant)
      const allUsagersGroupedBySalle = this.salleClasseUsagersToUsagersGroupedByClass(salleUsagers)

      this.selectedSallesUsager = salleUsagers
      this.displayedSallesUsagers = allUsagersGroupedBySalle
    },
    async getSalleUsagersFromCalendrierElementIdentifiant (identifiant) {
      const { data } = await this.$apollo.query({
        query: require('@/graphql/queries/v2/eleveSelection/searchSalleClasseSallesUsagersByCalendrierElement.gql'),
        variables: {
          filtre: { calendriersElements: [identifiant] }
        },
        fetchPolicy: 'no-cache'
      })
      const salleUsagers = data?.searchSalleClasseSallesUsagers?.resultat
      return salleUsagers
    },
    salleClasseUsagersToUsagersGroupedByClass (salleUsagers) {
      // This methods is used when loading a page where we want to display the list of SalleUsagers
      // Even if we did not opened the SelectElevesV2 popup yet
      const output = {}

      salleUsagers.forEach(su => {
        const salleId = su?.salle?.id
        const salleTitre = su?.salle?.titre
        if (!output[salleId]) {
          output[salleId] = {
            allChildrenLength: null,
            id: salleId,
            titre: salleTitre,
            selectedChildren: []
          }
        }

        output[salleId].selectedChildren.push({
          id: su.id,
          usagerId: su?.usager?.id,
          titre: su?.usager?.info?.prenom + ' ' + su?.usager?.info?.nom,
          avatar: su?.usager?.avatar?.thumbnail,
          initial: su?.usager?.info?.prenom?.[0] + su?.usager?.info?.nom?.[0],
          salle: salleId
        })
      })

      return Object.values(output)
    }
  }
}
