<script>
/*
Difference between selectEleves and selectElevesV2 ?
=> V2 uses SallesUsagers instead of Usagers
  (meaning that the same user from 2 distinct groups is treated as 2 distinct students)
=> V2 uses "searchSalleClasseSallesUsagers" request to filter SallesUsagers that can access a ressource
  (only if the nuageItem is passed as a props) and will not trigger any error for the user
=> V2 is a component included only in the pages using it. It is not present in EVERY page like V1 was
=> V2 is not connected to the store. Since it is imported in the page component and not in the hole app,
  it can interact with its parent by using Emit
=> V2 uses the subcomponent "GroupListSelectionPreview", a component that can easily be reused everywhere in the app
=> Also we are using some cool CSS transitions
*/

import EleveSelection from '@/mixins/eleveSelection'
import GroupListSelectionPreview from '@/components/groupes/GroupListSelectionPreview'

export default {
  name: 'SelectElevesV2',
  components: { GroupListSelectionPreview },
  mixins: [EleveSelection],
  data: () => ({
    selectedUsagers: [],
    showDialog: true,
    search: ''
  }),
  computed: {
    dark () {
      return this.$store.state.Preferences.darkMode
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('select', this.selectedUsagers)
      this.$emit('display', this.selectedUsagersGroupedByClass)
      this.close()
    }
  }
}
</script>

<template lang="pug">
v-dialog.select-eleves(v-model='showDialog' max-width='800' content-class='custom-dialog min-height' persistent scrollable)
  v-card.light

    //- Close button
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click='close' depressed fab x-large :color='dark ? "primary" : "light"')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title.secondary.white--text {{ $t("action.select-eleves") }}

    v-card-text.relative

      //- Search
      v-text-field.mt3(v-model='search' :disabled="loadingGroupes && selectedUsagers.length === 0" :label='$t("action.trouver-eleve")' :placeholder='$t("action.trouver-eleve")' rounded solo clearable clear-icon='mdi-close-circle-outline' hide-details light)

      //- Loading
      template(v-if='loadingGroupes')
        div.dialog-text.py5.px0.secondary--text
          div.b.f4.mb3.tc {{$t("partage.mes-groupes")}}
          v-progress-circular.mt3(indeterminate color='primary' height='10' rounded striped)
          div.f6 {{$t('messages.chargement-groupes')}}

      template(v-else)

        //- Alert if nuageItem is selected
        v-alert.mt-5.mb-0(v-if="nuageItems && nuageItems.length" type="warning" color="orange" dense elevation="3") {{ $t('eleves.filter-by-ressource') }}

        //- Choose group
        div.selection-wrapper
          section
            v-card-text
              div.section-title {{$t("partage.mes-groupes")}}
              v-treeview.eleves-treeview(
                ref='treeEleves'
                v-model='selectedUsagers'
                :items='groupsToDisplay'
                item-disabled='locked'
                rounded
                hoverable
                open-on-click
                return-object
                dense
                selectable
                selected-color='primary',
                light
              )
                template(v-slot:label ='{ item }')
                  div(style='line-height:1.15') {{ item.titre }}
                  div.f7.i.o-50(v-if="!item.usagerId") {{ $tc('eleves.eleve-multiple', item.nbUsagers, {nb: item.nbUsagers}) }}

          v-divider(:vertical='isVertical')

          //- Display selected groups
          section
            v-card-text
              div.section-title {{ $t("eleves.selected-groups") }}

              p(v-if="!selectedUsagersGroupedByClass || !selectedUsagersGroupedByClass.length" ) {{ $t("eleves.no-selected-groups") }}
              template(v-else)
                group-list-selection-preview(:groupList="selectedUsagersGroupedByClass")

    div.flex-grow-1
    v-btn.flex-grow-0(@click='submit' color='primary' depressed block x-large :disabled='!selectedUsagers || selectedUsagers.length < 1')
      span.f4 {{$t("action.select-ces-eleves")}}

</template>

<style lang="sass">
.min-height
  min-height: 70%
</style>
