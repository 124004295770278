<script>
import { xor } from 'lodash-es'

export default {
  name: 'GroupListSelectionPreview',
  props: {
    groupList: { type: Array, default: () => [] }
  },
  data: () => ({
    groupsToExpand: []
  }),
  computed: {
    groupWithTruncation () {
      return this.groupList.map(gl => {
        const countBeforeTruncate = 5
        const gCopy = JSON.parse(JSON.stringify(gl))
        gCopy.selectedStudentCount = gCopy.selectedChildren?.length ?? null
        gCopy.allSelected = gCopy.allChildrenLength === gCopy.selectedChildren?.length

        // Small groups: Show everybody
        if (gCopy.selectedChildren.length <= (countBeforeTruncate + 1)) {
          return { ...gCopy, children: gCopy.selectedChildren }
        }

        // Display expended group with "Show less" sign
        if (this.groupsToExpand.includes(gCopy.id)) {
          const children = gCopy.selectedChildren
          children.push({
            id: 'showLess',
            titre: this.$t('partage.see-less'),
            initial: '-'
          })
          gCopy.children = children
          return gCopy
        }

        // Display truncated group with "+XX students"
        const truncatedCount = gCopy.selectedChildren.length - countBeforeTruncate
        const children = gCopy.selectedChildren.splice(0, countBeforeTruncate)
        children.push({
          id: 'showMore',
          titre: this.$t('eleves.also-x-more', { nb: truncatedCount }),
          initial: '+' + truncatedCount
        })
        gCopy.children = children
        return gCopy
      })
    }
  },
  methods: {
    toggleExpand (studentId, groupId) {
      // The expand can only be available on "+" or "-" sign where there are no StudentId
      if (studentId) {
        return null
      }
      // Weird syntax: toggle element in array using lodash
      this.groupsToExpand = xor(this.groupsToExpand, [groupId])
    }
  }
}
</script>

<template lang="pug">
transition-group(name="slide-right" tag="div" class="relative")
  v-card(v-for="g in groupWithTruncation" :key="g.id")
    v-card-text
      div.f4.b
        span {{ g.titre }}
        span(v-if="!g.allSelected && g.selectedStudentCount && g.nbUsagers")  ({{ g.selectedStudentCount }} / {{ g.nbUsagers }})
      div.mb-2
        span(v-if="g.nbUsagers") {{ $tc("eleves.eleve-multiple-group", g.nbUsagers, { nb: g.nbUsagers } )}}

      transition-group(name="slide-bottom" tag="div" class="student-list" :class="{ 'not-truncated': g.children.length < 5 }")
        router-link.avatar(
          v-for="(student, index) in g.children"
          :key="student.id"
          :to="{ name: 'enfantDetail', params: { id: student.usagerId } }"
          :is="student.usagerId ? 'router-link' : 'div'"
          :class="{ toggler: !student.usagerId }"
          :style="{ 'z-index': index + 1 }"
          color="primary"
          v-tooltip.bottom="{ content: student.titre, offset:'10px'}"
          @click="toggleExpand(student.usagerId, g.id)"
        )
          img.avatar-thumbnail(v-if="student.avatar" :src="student.avatar")
          span(v-else) {{ student.initial }}
</template>

<style lang="sass" scoped>
.relative
  position: relative
  .v-card
    width: 100%
    &:not(:last-child)
      margin-bottom: 12px
.student-list
  display: flex
  flex-wrap: wrap
  margin-left: 12px
  .avatar
    display: flex
    justify-content: center
    align-items: center
    font-weight: bold
    color: white
    background-color: var(--v-primary-base)
    width: 48px
    height: 48px
    border-radius: 48px
    border: 2px solid white
    transition: all 0.2s ease
    text-decoration: none
    cursor: pointer
    margin-left: -12px
    &.toggler
      background-color: var(--v-secondary-base)
  &.not-truncated
    .avatar:not(:first-child)
      margin-left: -6px

.slide-right-move, .slide-right-enter-active, .slide-right-leave-active
  transition: all 0.3s ease !important

.slide-right-enter, .slide-right-leave-to, .slide-right-from, .slide-right-enter-to
  opacity: 0 !important
  transform: translateX(50px) !important

.slide-right-enter-to
  opacity: 1 !important
  transform: translateX(0px) !important

.slide-right-leave-active
  position: absolute !important

.slide-bottom-move, .slide-bottom-enter-active, .slide-bottom-leave-active
  transition: all 0.3s ease !important

.slide-bottom-enter, .slide-bottom-leave-to, .slide-bottom-from, .slide-bottom-enter-to
  opacity: 0 !important
  transform: translateY(15px) !important

.slide-bottom-enter-to
  opacity: 1 !important
  transform: translateY(0px) !important
</style>
