/*
This mixin is the logic for the SelectElevesV2 component
It should not be used anywhere else
*/
export default {
  data: () => ({
    filterAutorizedClasseUsagers: null,
    groupes: [],
    loadingGroupes: false,
    temporaryAllowedClasseUsagers: []
  }),
  props: {
    initialSelectedUsagers: { type: Array, default: () => [] },
    nuageItems: { type: Array, default: () => [] }
  },
  methods: {
    async getAllSallesClasses () {
      this.loadingGroupes = true

      const res = await this.$apollo.query({
        query: require('@/graphql/queries/v2/eleveSelection/searchSalleClasses.gql'),
        variables: {
          limit: 100
        }
      })
      const salleClasses = res?.data?.searchSalleClasses?.resultat ?? []
      const classes = salleClasses
        .filter(sc => sc.finAt === null || this.$dayjs().isBefore(sc.finAt))
        .map(this.salleClasseToSelectedStudentList)
        .sort((a, b) => {
          if (b?.nbUsagers === 0) return -1
          return 1
        })

      this.loadingGroupes = false
      return classes
    },
    async getAllAutorizedClasseUsagers () {
      this.temporaryAllowedClasseUsagers = []
      await this.getAutorizedClasseUsagers()
      return this.temporaryAllowedClasseUsagers
    },
    async getAutorizedClasseUsagers (page = 1) {
      this.loadingGroupes = true
      const fetchedUsagers = this.temporaryAllowedClasseUsagers
      const { data } = await this.$apollo.query({
        query: require('@/graphql/queries/v2/eleveSelection/searchSalleClasseSallesUsagersByNuageItem.gql'),
        variables: {
          filtre: { nuageItems: this.nuageItems },
          page
        }
      })

      const allowedClasseUsagersToAdd = data.searchSalleClasseSallesUsagers?.resultat?.map(csu => csu.id) ?? []
      this.temporaryAllowedClasseUsagers = [...fetchedUsagers, ...allowedClasseUsagersToAdd]
      if (data.searchSalleClasseSallesUsagers?.total > this.temporaryAllowedClasseUsagers.length) {
        await this.getAutorizedClasseUsagers(page + 1)
      }
      return true
    },
    salleClasseToSelectedStudentList (sc) {
      const children = sc?.sallesUsagers?.resultat?.map(u => ({
        id: u.id,
        usagerId: u?.usager?.id,
        titre: u?.usager?.info?.prenom + ' ' + u?.usager?.info?.nom,
        avatar: u?.usager?.avatar?.thumbnail,
        initial: u?.usager?.info?.prenom?.[0] + u?.usager?.info?.nom?.[0],
        salle: sc.id
      }))
      return {
        id: sc.id,
        titre: sc.titre,
        nbUsagers: sc.sallesUsagers?.total ?? null,
        children,
        locked: !sc.sallesUsagers?.total
      }
    },
    filterUsagerWithSearchTerms (terms, children) {
      return terms.every(t => children.titre.toLowerCase().includes(t))
    }
  },
  computed: {
    isVertical () {
      return this.$store.state.App.windowSize.width > 600
    },
    groupsToDisplay () {
      // Same as this.groups but we hide empty groups on smaller screens
      const groups = this.groupes
        ?.filter(g => this.isVertical || !g.locked)
        ?.map(g => {
          const children = this.filterAutorizedClasseUsagers === null
            ? g.children
            : g.children.map(c => {
              c.locked = !this.filterAutorizedClasseUsagers.includes(c.id)
              return c
            })
          g.children = children
          g.locked = g.locked ? g.locked : children.every(c => c.locked)
          return g
        })

      const s = this.search
      if (!s || s === '') {
        return groups
      }
      const terms = s.split(' ').map(t => t.toLowerCase())
      return groups
        .map(g => {
          const groupCopy = JSON.parse(JSON.stringify(g))
          groupCopy.children = groupCopy?.children.filter(c => this.filterUsagerWithSearchTerms(terms, c)) ?? []
          return groupCopy
        })
        .filter(g => {
          return g.children.length !== 0
        })
    },
    selectedUsagersGroupedByClass () {
      // This is only used for visualisation, not used for any requests
      const allowedClasseUsagersSet = new Set()
      const allowedClassesSet = new Set()

      this.selectedUsagers.forEach(su => {
        allowedClasseUsagersSet.add(su.id)
        allowedClassesSet.add(su.salle)
      })

      return this.groupes
        ?.filter(g => allowedClassesSet.has(g.id))
        ?.map(g => {
          const gCopy = JSON.parse(JSON.stringify(g))
          const selectedChildren = gCopy.children.filter(c => allowedClasseUsagersSet.has(c.id))
          return { ...gCopy, allChildrenLength: gCopy.children.length, selectedChildren }
        }) ?? []
    },
    selectedUsagersFilteredBySearch () {
      const s = this.search
      if (!s || s === '') {
        return this.selectedUsagers
      }
      return this.selectedUsagers
    }
  },
  async mounted () {
    if (this.nuageItems.length) {
      // This is not the fastest way to load, but it is the easiest
      // We could also fetch getAutorizedClasseUsagers and getAllSallesClasses in parallel to load faster
      this.filterAutorizedClasseUsagers = await this.getAllAutorizedClasseUsagers()
    }
    this.groupes = await this.getAllSallesClasses()
    this.selectedUsagers = this.initialSelectedUsagers
  }
}
